import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import io from 'socket.io-client';
import ReactGA from 'react-ga';
import GlobalContext from './contexts/global';
import loadingSVG from './images/loading_large.svg';
import logo from './images/logo.svg';
const ResumeBuilder = lazy(() => import('./pages/ResumeBuilder/ResumeBuilder'));

const socket = io(process.env.REACT_APP_SERVER_URL, {
    extraHeaders: {
        randomint: Math.floor(Math.random() * 1000000)
    }
});

const Loading = () => {
    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundPosition: 'center',
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 4rem'
        }}>
            <img src={loadingSVG} alt="Loading..." />
        </div >
    )
}

const App = () => {

    const [isOpen, setIsOpen] = useState({});
    const [cookies, setCookies] = useState(JSON.parse(localStorage.getItem('cookies')));

    useEffect(() => {
        if (process.env.REACT_APP_GA && cookies && cookies.cookies.analytical) {
            ReactGA.initialize(process.env.REACT_APP_GA);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [cookies]);

    const context = {
        socket,
        isOpen,
        setIsOpen,
        cookies,
        setCookies
    }

    return (
        <Suspense fallback={<Loading />}>
            <GlobalContext.Provider value={context}>
                <Router basename={'/resume-maker/'}>
                    <Switch>
                        <Route exact path="/" component={ResumeBuilder} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </Router>
            </GlobalContext.Provider>
        </Suspense>
    );
}

export default App;
