import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import './main.scss';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    Sentry.init({
        dsn: "https://b04aae38207047e19cde4bd0e83bd812@o484418.ingest.sentry.io/5924225",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

const root = document.getElementById('root');
render(<StrictMode><App /></StrictMode>, root);
